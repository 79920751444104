// AD GROUPS DEFINED BELOW MUST BE IN LOWER CASE
export const ADMIN = 'admin';
export const BACKEND_UNDERWRITER = 'beuw';
export const FRONTEND_UNDERWRITER = 'feuw';
export const MANAGER = 'manager';
export const FRONTEND_MANAGER = 'feuw-mgr';
export const STAGER = 'stager';
export const STAGER_MANAGER = 'stager-mgr';
export const DOC_PROCESSOR = 'proc';
export const DOC_GEN = 'docgen';
export const DOCS_IN = 'docsin';
export const BOOKING = 'booking';
export const COVIUS = 'docgenvendor';
export const COVIUS_MGR = 'docgenvendor-mgr';
export const FHLMCRESOLVE = 'fhlmcresolve';
export const RPS_STAGER = 'rpsstager';
export const RPS_STAGER_MGR = 'rpsstager-mgr';
export const INVSET = 'invset';
export const SECONDLOOK = 'secondlook';
