const ERROR = 'error';
const SUCCESS = 'success';
const FAILED = 'failed';
const NOTFOUND = 'NotFound';
const TRUE = 'true';
const FALSE = 'false';
const ALL = 'all';
const ANY = 'any';
const EXCEL_FORMATS = ['xlsx', 'xls'];

module.exports = {
  NOTFOUND,
  ERROR,
  FAILED,
  SUCCESS,
  TRUE,
  FALSE,
  ALL,
  ANY,
  EXCEL_FORMATS,
};
