const FETCHCONFIG = 'app/config/FETCHCONFIG';
const FETCHCONFIG_SAGA = 'app/config/FETCHCONFIG_SAGA';
const POWER_BI_CONSTANTS = 'app/config/POWER_BI_CONSTANTS';
const POWER_BI_CONSTANTS_SUCCESS = 'app/config/POWER_BI_CONSTANTS_SUCCESS';
const POWER_BI_CONSTANTS_FAILURE = 'app/config/POWER_BI_CONSTANTS_FAILURE';
const POWER_BI_CONSTANTS_SAGA = 'app/config/POWER_BI_CONSTANTS_SAGA';
const GET_FEATURES_SAGA = 'app/config/GET_FEATURES_SAGA';
const SET_FEATURES = 'app/config/SET_FEATURES';
const GET_PDFGENRATOR_URL = 'app/task-and-checklist/GET_PDFGENRATOR_URL';
const SET_PDFGENRATOR_URL = 'app/task-and-checklist/SET_PDFGENRATOR_URL';
const TOGGLE_ICON = 'app/task-and-checklist/TOGGLE_ICON';
const TOGGLE_HIDDEN_ROUTE = 'app/task-and-checklist/TOGGLE_HIDDEN_ROUTE';
const CONFIG_DATA_SUCCESS = 'app/config/FETHCING_DATA_SUCCESS';
const CONFIG_DATA_FAILURE = 'app/config/FETCHING_DATA_FAILURE';


export {
  FETCHCONFIG,
  FETCHCONFIG_SAGA,
  POWER_BI_CONSTANTS,
  POWER_BI_CONSTANTS_FAILURE,
  POWER_BI_CONSTANTS_SAGA,
  POWER_BI_CONSTANTS_SUCCESS,
  GET_FEATURES_SAGA,
  SET_FEATURES,
  GET_PDFGENRATOR_URL,
  SET_PDFGENRATOR_URL,
  TOGGLE_ICON,
  TOGGLE_HIDDEN_ROUTE,
  CONFIG_DATA_SUCCESS,
  CONFIG_DATA_FAILURE,
};
