import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'components/Loader/Loader';
import { selectors, operations } from 'ducks/income-calculator';
import { selectors as dashboardSelectors, operations as dashboardOperations } from 'ducks/dashboard';
import ComponentTypes from 'constants/componentTypes';
import * as R from 'ramda';
import IncomeChecklist from 'components/IncomeCalc/IncomeChecklist';
import { selectors as widgetsSelectors } from 'ducks/widgets';
import './IncomeCalcTempo.css';
import Controls from 'containers/Controls';
import ErrorBanner from 'components/ErrorBanner';
import {
  FINANCIAL_CALCULATOR,
} from '../../constants/widgets';
import Popup from '../../components/Popup';
import Navigation from '../Dashboard/TasksAndChecklist/Navigation';

class IncomeCalcTempo extends React.Component {
  constructor(props) {
    super(props);
    const loanId = R.pathOr(null, ['match', 'params', 'loanId'], props);
    const clientId = R.pathOr(null, ['match', 'params', 'clientId'], props);
    const borrowerFinancialDiscoveryId = R.pathOr(null, ['match', 'params', 'borrowerFinancialDiscoveryId'], props);
    const userId = R.pathOr(null, ['match', 'params', 'userId'], props);
    const processId = R.pathOr(null, ['match', 'params', 'processId'], props);
    this.state = {
      loanId,
      clientId,
      borrowerFinancialDiscoveryId,
      processId,
      userId,
    };
  }

  componentDidMount() {
    const {
      fetchIncomeCalcTempo,
    } = this.props;
    const {
      loanId, clientId, borrowerFinancialDiscoveryId, processId, userId,
    } = this.state;
    if (loanId && clientId && borrowerFinancialDiscoveryId) {
      fetchIncomeCalcTempo({
        loanId, clientId, borrowerFinancialDiscoveryId, processId, userId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      incomeCalcChecklist, openWidgetList, processInstance, type,
    } = this.props;
    if (!R.contains(FINANCIAL_CALCULATOR, openWidgetList)
    && type !== prevProps.type) {
      incomeCalcChecklist({ processInstance, type });
    }
  }

  renderSweetAlert() {
    const { clearPopupData, popupData, dispatchAction } = this.props;
    if (popupData) {
      const {
        isOpen, message, title, level, showCancelButton,
        cancelButtonText, confirmButtonText, onConfirm,
      } = popupData;
      const confirmAction = onConfirm ? dispatchAction : clearPopupData;
      return (
        <Popup
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          level={level}
          message={message}
          onCancel={clearPopupData}
          onConfirm={() => confirmAction(onConfirm)}
          show={isOpen}
          showCancelButton={showCancelButton}
          showConfirmButton
          title={title}
        />
      );
    }
    return null;
  }

  render() {
    const {
      inProgress, checklistItems, onChecklistChange, historyView,
      disabledChecklist, openWidgetList, type, showBanner, errorBanner,
      isError,
    } = this.props;
    if (inProgress) {
      return (
        <div styleName="income-loader">
          <Loader message="Please Wait" />
        </div>
      );
    }


    if (R.contains(FINANCIAL_CALCULATOR, openWidgetList)) {
      return (
        <div>
          <div styleName="income-checklist">
            {showBanner && <ErrorBanner errorBanner={errorBanner} />}
            <IncomeChecklist
              checklistItems={checklistItems}
              checklistType={type}
              disabled={disabledChecklist || historyView}
              onChange={onChecklistChange}
              onDelete={this.onDeleteItem}
              styleName="widget"
            />
          </div>
          {this.renderSweetAlert()}
        </div>
      );
    }

    return (
      <>
        {isError ? (
          <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          >
            <img alt="no loan page placeholder" src="/static/img/invalid-loan.png" />
            <p style={{
              fontSize: '20px',
              fontWeight: 'bold',
              color: 'gray',
              textAlign: 'center',
            }}
            >
            Unable to Load Income Calculation Checklist, for the Loan Details,
              <br />
              Kindly contact the CMOD Integration team
            </p>
          </div>
        ) : (
          <>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '5rem',
              marginTop: '1rem',
              maxWidth: '70%',
            }}
            >
              <Controls />
            </div>
            {showBanner && <ErrorBanner errorBanner={errorBanner} />}
            <IncomeChecklist
              checklistItems={checklistItems}
              checklistType={type}
              disabled={disabledChecklist || historyView}
              onChange={onChecklistChange}
              onDelete={this.onDeleteItem}
              styleName="checklist"
            />
            {this.renderSweetAlert()}
            <Navigation />
          </>
        )}
      </>
    );
  }
}


IncomeCalcTempo.defaultProps = {
  inProgress: false,
  checklistItems: [],
  historyView: false,
  disabledChecklist: false,
  openWidgetList: [],
  type: 'income-calculator',
  showBanner: false,
  isError: false,
};

IncomeCalcTempo.propTypes = {
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })),
      showPushData: PropTypes.bool,
      taskCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(ComponentTypes)).isRequired,
    }),
  ),
  clearPopupData: PropTypes.func.isRequired,
  disabledChecklist: PropTypes.bool,
  dispatchAction: PropTypes.func.isRequired,
  errorBanner: PropTypes.shape({
    errors: PropTypes.array,
    warnings: PropTypes.array,
  }).isRequired,
  fetchIncomeCalcTempo: PropTypes.func.isRequired,
  historyView: PropTypes.bool,
  incomeCalcChecklist: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  isError: PropTypes.bool,
  onChecklistChange: PropTypes.func.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  popupData: PropTypes.shape({
    cancelButtonText: PropTypes.string,
    clearData: PropTypes.string,
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    showCancelButton: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    title: PropTypes.string,
  }).isRequired,
  processInstance: PropTypes.string.isRequired,
  showBanner: PropTypes.bool,
  type: PropTypes.string,
};

const mapStateToProps = state => ({
  borrowersInfo: selectors.getBorrowersInfo(state),
  inProgress: selectors.inProgress(state),
  loanNumber: dashboardSelectors.loanNumber(state),
  getIncomeType: selectors.getIncomeType(state),
  getSelectedBorrower: selectors.getSelectedBorrower(state),
  checklistItems: selectors.getChecklistItems(state),
  historyView: selectors.getHistoryView(state),
  disabledChecklist: selectors.disabledChecklist(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  errorBanner: selectors.getErrorBanner(state),
  showBanner: dashboardSelectors.showBanner(state),
  isError: selectors.getIsError(state),
  popupData: dashboardSelectors.getPopupData(state),
});

const mapDispatchToProps = dispatch => ({
  incomeCalcChecklist: operations.incomeCalcChecklist(dispatch),
  onChecklistChange: operations.handleChecklistItemValueChange(dispatch),
  fetchIncomeCalcTempo: operations.fetchIncomeCalcTempo(dispatch),
  clearPopupData: dashboardOperations.clearPopupData(dispatch),
  dispatchAction: dashboardOperations.dispatchAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncomeCalcTempo);
