const COMMENTS = 'Comments';
const ADDITIONAL_INFO = 'Additional Info';
const HISTORY = 'History';
const CUSTOM_COMM_LETTER = 'Customer Communication Letter';
const BOOKING = 'BookingAutomation';
const FINANCIAL_CALCULATOR = 'FinancialCalculator';
const FHLMC = 'FHLMC';

const widgets = [
  COMMENTS,
  ADDITIONAL_INFO,
  HISTORY,
  CUSTOM_COMM_LETTER,
  BOOKING,
  FINANCIAL_CALCULATOR,
  FHLMC,
];

export {
  COMMENTS,
  ADDITIONAL_INFO,
  HISTORY,
  CUSTOM_COMM_LETTER,
  BOOKING,
  FINANCIAL_CALCULATOR,
  widgets,
  FHLMC,
};
