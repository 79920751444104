const AUTO_SAVE_TRIGGER = 'app/dashboard/AUTO_SAVE_TRIGGER';
const AUTO_SAVE_OPERATIONS = 'app/dashboard/AUTO_SAVE_OPERATIONS';
const CLEAR_DISPOSITION = 'app/dashboard/CLEAR_DISPOSITION';
const CLEAR_FIRST_VISIT = 'app/dashboard/CLEAR_FIRST_VISIT';
const END_SHIFT = 'app/dashboard/END_SHIFT';
const CHECKLIST_NOT_FOUND = 'app/dashboard/CHECKLIST_NOT_FOUND';
const TASKS_NOT_FOUND = 'app/dashboard/TASKS_NOT_FOUND';
const GET_NEXT_ERROR = 'app/dashboard/GET_NEXT_ERROR';
const TASKS_FETCH_ERROR = 'app/dashboard/TASKS_FETCH_ERROR';
const GET_NEXT = 'app/dashboard/GET_NEXT';
const SET_EXPAND_VIEW_SAGA = 'app/dashboard/set_expand_view_saga';
const SET_EXPAND_VIEW = 'app/dashboard/SET_EXPAND_VIEW';
const SUCCESS_END_SHIFT = 'app/dashboard/SUCCESS_END_SHIFT';
const VALIDATE_DISPOSITION_SAGA = 'app/dashboard/VALIDATE_DISPOSITION_SAGA';
const SAVE_DISPOSITION_SAGA = 'app/dashboard/SAVE_DISPOSITION_SAGA';
const SAVE_DISPOSITION = 'app/dashboard/SAVE_DISPOSITION';
const SET_GET_NEXT_STATUS = 'app/dashboard/SET_GET_NEXT_STATUS';
const USER_NOTIF_MSG = 'app/dashboard/USER_NOTIF_MSG';
const SAVE_EVALID_LOANNUMBER = 'app/dashboard/SAVE_EVALID_LOANNUMBER';
const SAVE_SELECTED_DISPOSITION = 'app/dashboard/SAVE_SELECTED_DISPOSITION';
const SHOW_LOADER = 'app/dasboard/SHOW_LOADER';
const GETNEXT_PROCESSED = 'app/dasboard/GETNEXT_PROCESSED';
const SHOW_SAVING_LOADER = 'app/dashboard/SHOW_SAVING_LOADER';
const HIDE_SAVING_LOADER = 'app/dashboard/HIDE_SAVING_LOADER';
const HIDE_LOADER = 'app/dashboard/HIDE_LOADER';
const SEARCH_LOAN_RESULT = 'app/dashboard/SEARCH_LOAN_RESULT';
const SEARCH_LOAN_TRIGGER = 'app/dashboard/SEARCH_LOAN_TRIGGER';
const UNASSIGN_LOAN = 'app/dashboard/UNASSIGN_LOAN';
const UNASSIGN_LOAN_RESULT = 'app/dashboard/UNASSIGN_LOAN_RESULT';
const ASSIGN_LOAN = 'app/dashboard/ASSIGN_LOAN';
const ASSIGN_TO_ME_CLICK = 'app/dashboard/ASSIGN_TO_ME_CLICK';
const ASSIGN_LOAN_RESULT = 'app/dashboard/ASSIGN_LOAN_RESULT';
const HIDE_ASSIGN_UNASSIGN = 'app/dashboard/HIDE_ASSIGN_UNASSIGN';
const POST_COMMENT = 'app/dashboard/POST_COMMENT';
const CLEAR_BE_DISPOSITION = 'CLEAR_BE_DISPOSITION';
const GROUP_NAME = 'GROUP_NAME';
const SAVE_LOANNUMBER_PROCESSID = 'app/dashboard/SAVE_EVALID_LOANNUMBER';
const SEARCH_SELECT_EVAL = 'app/dashboard/SEARCH_SELECT_EVAL';
const DISPLAY_ASSIGN = 'DISPLAY_ASSIGN';
const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
const LOAD_TRIALHEADER_RESULT = 'app/dashboard/LOAD_TRIALHEADER_RESULT';
const LOAD_TRIALSDETAIL_RESULT = 'app/dashboard/LOAD_TRIALSDETAIL_RESULT';
const LOAD_TRIALLETTER_RESULT = 'app/dashboard/LOAD_TRIALLETTER_RESULT';
const LOAD_TRIALS_SAGA = 'app/dashboard/LOAD_TRIAL_SAGA';
const SET_TASK_UNDERWRITING = 'app/dashboard/SET_TASK_UNDERWRITING';
const SET_TASK_UNDERWRITING_RESULT = 'app/dashboard/SET_TASK_UNDERWRITING_RESULT';
const PUT_PROCESS_NAME = 'app/dashboard/PUT_PROCESS_NAME';
const SET_TASK_SENDTO_DOCGEN = 'app/dashboard/SET_TASK_SENDTO_DOCGEN';
const SET_RESULT_OPERATION = 'app/dashboard/SET_RESULT_OPERATION';
const CLEAN_RESULT = 'app/dashboard/CLEAN_RESULT';
const CONTINUE_MY_REVIEW = 'app/dashboard/CONTINUE_MY_REVIEW';
const COMPLETE_MY_REVIEW = 'app/dashboard/COMPLETE_MY_REVIEW';
const CONTINUE_MY_REVIEW_RESULT = 'app/dashboard/CONTINUE_MY_REVIEW_RESULT';
const COMPLETE_MY_REVIEW_RESULT = 'app/dashboard/COMPLETE_MY_REVIEW_RESULT';
const SET_ADD_DOCS_IN = 'app/dashboard/SET_ADD_DOCS_IN';
const PROCESS_COVIUS_BULK = 'app/dashboard/PROCESS_COVIUS_BULK';
const SET_ADD_BULK_ORDER_RESULT = 'app/dashboard/SET_ADD_BULK_ORDER_RESULT';
const SET_BEGIN_SEARCH = 'app/dashboard/SET_BEGIN_SEARCH';
const SET_ENABLE_SEND_BACK_GEN = 'app/dashboard/SET_ENABLE_SEND_BACK_GEN';
const SET_TRIAL_DISABLE_STAGER_BUTTON = 'app/dashboard/SET_TRIAL_DISABLE_STAGER_BUTTON';
const CHECK_TRIAL_DISABLE_STAGER_BUTTON = 'app/dashboard/CHECK_TRIAL_DISABLE_STAGER_BUTTON';
const SET_BULK_UPLOAD_RESULT = 'app/dashboard/SET_BULK_UPLOAD_RESULT';
const SET_BULK_UPLOAD_PAGE_TYPE = 'app/dashboard/SET_BULK_UPLOAD_PAGE_TYPE';
const GET_FHLMC_MOD_HISTORY = 'app/dashboard/GET_FHLMC_MOD_HISTORY';
const SET_FHLMC_MOD_HISTORY = 'app/dashboard/SET_FHLMC_MOD_HISTORY';
const CLEAR_POPUP_TABLE_DATA = 'app/dashboard/CLEAR_POPUP_TABLE_DATA';
const SET_TASK_SENDTO_DOCSIN = 'app/dashboard/SET_TASK_SENDTO_DOCSIN';
const SET_TASK_SENDTO_BOOKING = 'app/dashboard/SET_TASK_SENDTO_BOOKING';
const SET_ENABLE_SEND_BACK_DOCSIN = 'app/dashboard/SET_ENABLE_SEND_BACK_DOCSIN';
const SET_ENABLE_SEND_TO_BOOKING = 'app/dashboard/SET_ENABLE_SEND_TO_BOOKING';
const CLEAR_USER_NOTIF_MSG = 'app/dashboard/CLEAR_USER_NOTIF_MSG';
const SET_ENABLE_SEND_TO_UW = 'app/dashboard/SET_ENABLE_SEND_TO_UW';
const SELECT_REJECT_SAGA = 'app/dashboard/SELECT_REJECT_SAGA';
const SELECT_REJECT = 'app/dashboard/SELECT_REJECT';
const CLEAR_SELECT_REJECT = 'app/dashboard/CLEAR_SELECT_REJECT';
const SEARCH_LOAN_WITH_TASK_SAGA = 'app/dashboard/SEARCH_LOAN_WITH_TASK_SAGA';
const SEARCH_LOAN_WITH_TASK = 'app/dashboard/SEARCH_LOAN_WITH_TASK';
const SET_STAGER_TASK_NAME = 'app/dashboard/SET_STAGER_TASK_NAME';
const SET_STAGER_VALUE_STATE = 'app/dashboard/SET_STAGER_VALUE_STATE';
const MOD_REVERSAL_REASONS = 'app/dashboard/MOD_REVERSAL_REASONS';
const MOD_REVERSAL_DROPDOWN_VALUES = 'app/dashboard/MOD_REVERSAL_DROPDOWN_VALUES';
const POSTMOD_END_SHIFT = 'app/dashboard/POSTMOD_END_SHIFT';
const CLEAR_POSTMOD_END_SHIFT = 'app/dashboard/CLEAR_POSTMOD_END_SHIFT';
const CLEAR_BULKUPLOAD_TABLEDATA = 'app/dashboard/CLEAR_BULKUPLOAD_TABLEDATA';
const SET_INCENTIVE_TASKCODES = 'app/dashboard/SET_INCENTIVE_TASKCODES';
const INSERT_EVALID = 'app/dashboard/INSERT_EVALID';
const STORE_EVALID_RESPONSE = 'app/dashboard/STORE_EVALID_RESPONSE';
const STORE_EVALID_RESPONSE_ERROR = 'app/dashboard/STORE_EVALID_RESPONSE';
const RESOLUTION_DROP_DOWN_VALUES = 'app/dashboard/RESOLUTION_DROP_DOWN_VALUES';
const TRIAL_TASK = 'app/dashboard/TRIAL_TASK';
const SET_TRIAL_RESPONSE = 'app/dashboard/SET_TRIAL_RESPONSE';
const DISABLE_TRIAL_BUTTON = 'app/dashboard/DISABLE_TRIAL_BUTTON';
const DISCARD_EVAL_RESPONSE = 'app/dashboard/DISCARD_EVAL_RESPONSE';
const PROCESS_FILE = 'app/dashboard/PROCESS_FILE';
const SAVE_PROCESSED_FILE = 'app/dashboard/SAVE_PROCESSED_FILE';
const DELETE_FILE = 'app/dashboard/DELETE_FILE';
const SUBMIT_FILE = 'app/dashboard/SUBMIT_FILE';
const SET_SUBMIT_RESPONSE = 'app/dashboard/SET_SUBMIT_RESPONSE';
const SET_COVIUS_DATA = 'app/dashboard/SET_COVIUS_DATA';
const CLEAR_COVIUS_SUBMIT_DATA = 'app/dashboard/CLEAR_COVIUS_SUBMIT_DATA';
const CLEAR_DATA = 'app/dashboard/CLEAR_DATA';
const DOWNLOAD_FILE = 'app/dashboard/DOWNLOAD_FILE';
const SET_DOWNLOAD_RESPONSE = 'app/dashboard/SET_DOWNLOAD_RESPONSE';
const POPULATE_EVENTS_DROPDOWN = 'app/dashboard/POPULATE_EVENTS_DROPDOWN';
const POPULATE_INVESTOR_EVENTS_DROPDOWN = 'app/dashboard/POPULATE_INVESTOR_EVENTS_DROPDOWN';
const SAVE_EVENTS_DROPDOWN = 'app/dashboard/SAVE_EVENTS_DROPDOWN';
const SEND_TO_FEUW_SAGA = 'app/dashboard/SEND_TO_FEUW_SAGA';
const SEND_TO_COVIUS = 'app/dashboard/SEND_TO_COVIUS';
const SAVE_SENDTOCOVIUS_RESPONSE = 'app/dashboard/SAVE_SENDTOCOVIUS_RESPONSE';
const OPEN_SWEET_ALERT = 'app/dashboard/OPEN_SWEET_ALERT';
const CLOSE_SWEET_ALERT = 'app/dashboard/CLOSE_SWEET_ALERT';
const SET_COVIUS_TABINDEX = 'app/dashboard/SET_COVIUS_TABINDEX';
const DISABLE_SEND_TO_FEUW = 'app/dashboard/DISABLE_SEND_TO_FEUW';
const ASSIGN_BOOKING_LOAN = 'app/dashboard/ASSIGN_BOOKING_LOAN';
const SAVE_EVAL_FOR_WIDGET = 'app/dashboard/SAVE_EVAL_FOR_WIDGET';
const UNASSIGN_BOOKING_LOAN = 'app/dashboard/UNASSIGN_BOOKING_LOAN';
const SAVE_MAIN_CHECKLIST = 'app/dashboard/SAVE_MAIN_CHECKLIST';
const SET_USER_NOTIF_MESSAGE = 'app/dashboard/SET_USER_NOTIF_MESSAGE';
const SAVE_TASKID = 'app/dashboard/SAVE_TASKID';
const DISABLE_PUSHDATA = 'app/dashboard/DISABLE_PUSHDATA';
const SET_PAYMENT_DEFERRAL = 'app/dashboard/SET_PAYMENT_DEFERRAL';
const SET_SELECTED_WIDGET = 'app/dashboard/SET_SELECTED_WIDGET';
const TOGGLE_LOCK_BUTTON = 'app/dashboard/TOGGLE_LOCK_BUTTON';
const SET_POPUP_DATA = 'app/dashboard/SET_POPUP_DATA';
const CLEAR_POPUP_DATA = 'app/dashboard/CLEAR_POPUP_DATA';
const TOGGLE_INCVRFN = 'app/dashboard/TOGGLE_INCVRFN';
const EVAL_CASE_DETAILS = 'app/dashboard/EVAL_CASE_DETAILS';
const EVAL_ROW_CLICK = 'app/dashboard/EVAL_ROW_CLICK';
const SET_CASE_DETAILS = 'app/dashboard/CASE_DETAILS';
const SET_EVAL_INDEX = 'app/dashboard/SET_EVAL_INDEX';
const SET_TOMBSTONE_DATA_FOR_LOANVIEW = 'app/dashboard/SET_TOMBSTONE_DATA_FOR_LOANVIEW';
const SAVE_EVAL_LOANVIEW = 'app/dashboard/SAVE_EVAL_LOANVIEW';
const PROCESS_FHLMC_RESOSLVE_BULK = 'app/dashboard/PROCESS_FHLMC_RESOSLVE_BULK';
const SAVE_INVESTOR_EVENTS_DROPDOWN = 'app/dashboard/SAVE_INVESTOR_EVENTS_DROPDOWN';
const SUBMIT_TO_FHLMC = 'app/dashboard/SUBMIT_TO_FHLMC';
const SET_USER_NOTIFICATION = 'app/dashboard/SET_USER_NOTIFICATION';
const DISMISS_USER_NOTIFICATION = 'app/dashboard/DISMISS_USER_NOTIFICATION';
const SET_FHLMC_UPLOAD_RESULT = 'app/dashboard/SET_FHLMC_UPLOAD_RESULT';
const FETCH_EVAL_CASE = 'app/dashboard/FETCH_EVAL_CASE';
const OPEN_INCOME_CALC = 'app/dashboard/OPEN_INCOME_CALC';
const TOGGLE_BANNER = 'app/dashboard/TOGGLE_BANNER';
const SET_RESOLUTION_AND_INVSTR_HRCHY = 'app/dashboard/SET_RESOLUTION_AND_INVSTR_HRCHY';
const GET_ELIGIBLE_DATA = 'app/dashboard/GET_ELIGIBLE_DATA';
const SET_REQUEST_TYPE_DATA = 'app/dashboard/SET_REQUEST_TYPE_DATA';
const SET_EVALID = 'app/dashboard/SET_EVALID';
const SET_VALID_EVALDATA = 'app/dashboard/SET_VALID_EVALDATA';
const SET_BRAND = 'app/dashboard/SET_BRAND';
const SAVE_APPROVAL_DROPDOWN = 'app/dashboard/SAVE_APPROVAL_DROPDOWN';
const SAVE_PREAPPROVAL_DROPDOWN = 'app/dashboard/SAVE_PREAPPROVAL_DROPDOWN';
const SUBMIT_TO_BOARDING_TEMPLATE = 'app/dashboard/SUBMIT_TO_BOARDING_TEMPLATE';
const GET_CANCELLATION_REASON = 'app/dashboard/GET_CANCELLATION_REASON';
const SET_CANCELLATION_REASON = 'app/dashboard/SET_CANCELLATION_REASON';
const SET_SELECTED_CANCELLATION_REASON = 'app/dashboard/SET_SELECTED_CANCELLATION_REASON';
const CLEAR_CANCELLATION_REASONS = 'app/dashboard/CLEAR_CANCELLATION_REASONS';
const SET_DISABLE_GENERATE_BOARDING_TEMPLATE = 'app/dashboard/SET_DISABLE_GENERATE_BOARDING_TEMPLATE';
const STORE_INVEST_CD_AND_BRAND_NM = 'app/dashboard/STORE_INVEST_CD_AND_BRAND_NM';
const SET_EXCEPTION_REVIEW_INDICATOR = 'app/dashboard/SET_EXCEPTION_REVIEW_INDICATOR';
const SET_EXCEPTION_REVIEW_COMMENTS = 'app/dashboard/EXCEPTION_REVIEW_COMENTS';
const FETCH_CASEIDS = 'app/dashboard/FETCH_CASEIDS';
const SET_CASEIDS = 'app/dashboard/SET_CASEIDS';
const SET_ENQUIRY_CASE_ID = 'app/dashboard/SET_EQUIRY_CASE_ID';
const SET_DISABLE_SUBMITTOFHLMC = 'app/dashboard/SET_DISABLE_SUBMITTOFHLMC';
const SET_MILESTONE_DETAILS = 'app/dashboard/SET_MILESTONE_DETAILS';
const SET_CURRENT_MILESTONE = 'app/dashboard/SET_CURRENT_MILESTONE';
const SET_DISASTER_TYPE = 'app/dashboard/SET_DISASTER_TYPE';
const SET_TRIAL_DATE_INFO = 'app/dashboard/SET_TRIAL_DATE_INFO';
const UPDATE_TRIAL_PERIOD = 'app/dashboard/UPDATE_TRIAL_PERIOD';
const UPDATE_TRIAL_PERIOD_RESULT = 'app/dashboard/UPDATE_TRIAL_PERIOD_RESULT';
const ODM_RERUN_SAGA = 'app/dashboard/ODM_RERUN_SAGA';
const ENABLE_ODM_RERUN_BUTTON = 'app/dashboard/ENABLE_ODM_RERUN_BUTTON';
const DISABLE_SAVE = 'app/dashboard/DISABLE_SAVE';
const DISABLE_FINANCE_CALC_TAB_BUTTON = 'app/dashboard/DISABLE_FINANCE_CALC_TAB_BUTTON';
const RESET_DATA = 'app/dashboard/RESET_DATA';
const SET_LOAN_NUMBER = 'app/dashboard/SET_LOAN_NUMBER';

export {
  ENABLE_ODM_RERUN_BUTTON,
  ODM_RERUN_SAGA,
  SET_DISASTER_TYPE,
  SET_CURRENT_MILESTONE,
  SET_MILESTONE_DETAILS,
  SET_BRAND,
  SET_USER_NOTIFICATION,
  DISMISS_USER_NOTIFICATION,
  SUBMIT_TO_FHLMC,
  SAVE_INVESTOR_EVENTS_DROPDOWN,
  POPULATE_INVESTOR_EVENTS_DROPDOWN,
  PROCESS_FHLMC_RESOSLVE_BULK,
  TOGGLE_BANNER,
  OPEN_INCOME_CALC,
  TOGGLE_INCVRFN,
  SET_POPUP_DATA,
  CLEAR_POPUP_DATA,
  FETCH_EVAL_CASE,
  SET_PAYMENT_DEFERRAL,
  SAVE_TASKID,
  SET_USER_NOTIF_MESSAGE,
  SAVE_MAIN_CHECKLIST,
  UNASSIGN_BOOKING_LOAN,
  SET_COVIUS_TABINDEX,
  SAVE_EVAL_FOR_WIDGET,
  OPEN_SWEET_ALERT,
  CLOSE_SWEET_ALERT,
  SEND_TO_COVIUS,
  SAVE_SENDTOCOVIUS_RESPONSE,
  DISCARD_EVAL_RESPONSE,
  STORE_EVALID_RESPONSE_ERROR,
  STORE_EVALID_RESPONSE,
  INSERT_EVALID,
  CLEAR_DATA,
  SET_INCENTIVE_TASKCODES,
  AUTO_SAVE_TRIGGER,
  AUTO_SAVE_OPERATIONS,
  CLEAR_DISPOSITION,
  CLEAR_ERROR_MESSAGE,
  CLEAR_FIRST_VISIT,
  DISPLAY_ASSIGN,
  END_SHIFT,
  CHECKLIST_NOT_FOUND,
  TASKS_NOT_FOUND,
  GET_NEXT_ERROR,
  GET_NEXT,
  SET_EXPAND_VIEW,
  SET_EXPAND_VIEW_SAGA,
  VALIDATE_DISPOSITION_SAGA,
  SAVE_DISPOSITION_SAGA,
  SAVE_DISPOSITION,
  SAVE_EVALID_LOANNUMBER,
  SAVE_SELECTED_DISPOSITION,
  SHOW_SAVING_LOADER,
  HIDE_SAVING_LOADER,
  USER_NOTIF_MSG,
  TASKS_FETCH_ERROR,
  SUCCESS_END_SHIFT,
  SHOW_LOADER,
  HIDE_LOADER,
  SEARCH_LOAN_RESULT,
  SEARCH_LOAN_TRIGGER,
  UNASSIGN_LOAN,
  UNASSIGN_LOAN_RESULT,
  ASSIGN_LOAN,
  ASSIGN_TO_ME_CLICK,
  ASSIGN_LOAN_RESULT,
  HIDE_ASSIGN_UNASSIGN,
  POST_COMMENT,
  CLEAR_BE_DISPOSITION,
  GROUP_NAME,
  SAVE_LOANNUMBER_PROCESSID,
  SET_GET_NEXT_STATUS,
  SEARCH_SELECT_EVAL,
  // GET_LOAN_ACTIVITY_DETAILS,
  LOAD_TRIALS_SAGA,
  LOAD_TRIALHEADER_RESULT,
  LOAD_TRIALSDETAIL_RESULT,
  LOAD_TRIALLETTER_RESULT,
  SET_TASK_UNDERWRITING,
  SET_TASK_UNDERWRITING_RESULT,
  GETNEXT_PROCESSED,
  PUT_PROCESS_NAME,
  SET_TASK_SENDTO_DOCGEN,
  SET_RESULT_OPERATION,
  CLEAN_RESULT,
  CONTINUE_MY_REVIEW,
  CONTINUE_MY_REVIEW_RESULT,
  COMPLETE_MY_REVIEW_RESULT,
  COMPLETE_MY_REVIEW,
  SET_ADD_DOCS_IN,
  SET_ADD_BULK_ORDER_RESULT,
  SET_BEGIN_SEARCH,
  SET_ENABLE_SEND_BACK_GEN,
  SET_BULK_UPLOAD_RESULT,
  SET_BULK_UPLOAD_PAGE_TYPE,
  GET_FHLMC_MOD_HISTORY,
  SET_FHLMC_MOD_HISTORY,
  CLEAR_POPUP_TABLE_DATA,
  SET_TASK_SENDTO_DOCSIN,
  SET_TASK_SENDTO_BOOKING,
  SET_ENABLE_SEND_BACK_DOCSIN,
  SET_ENABLE_SEND_TO_BOOKING,
  CLEAR_USER_NOTIF_MSG,
  SET_ENABLE_SEND_TO_UW,
  SELECT_REJECT_SAGA,
  SELECT_REJECT,
  CLEAR_SELECT_REJECT,
  SEARCH_LOAN_WITH_TASK_SAGA,
  SEARCH_LOAN_WITH_TASK,
  SET_STAGER_TASK_NAME,
  SET_STAGER_VALUE_STATE,
  MOD_REVERSAL_REASONS,
  MOD_REVERSAL_DROPDOWN_VALUES,
  POSTMOD_END_SHIFT,
  CLEAR_POSTMOD_END_SHIFT,
  CLEAR_BULKUPLOAD_TABLEDATA,
  RESOLUTION_DROP_DOWN_VALUES,
  TRIAL_TASK,
  SET_TRIAL_RESPONSE,
  DISABLE_TRIAL_BUTTON,
  PROCESS_COVIUS_BULK,
  PROCESS_FILE,
  SAVE_PROCESSED_FILE,
  DELETE_FILE,
  SUBMIT_FILE,
  SET_SUBMIT_RESPONSE,
  SET_COVIUS_DATA,
  CLEAR_COVIUS_SUBMIT_DATA,
  DOWNLOAD_FILE,
  SET_DOWNLOAD_RESPONSE,
  POPULATE_EVENTS_DROPDOWN,
  SAVE_EVENTS_DROPDOWN,
  SEND_TO_FEUW_SAGA,
  DISABLE_SEND_TO_FEUW,
  ASSIGN_BOOKING_LOAN,
  DISABLE_PUSHDATA,
  SET_SELECTED_WIDGET,
  TOGGLE_LOCK_BUTTON,
  EVAL_CASE_DETAILS,
  EVAL_ROW_CLICK,
  SET_CASE_DETAILS,
  SET_EVAL_INDEX,
  SET_TOMBSTONE_DATA_FOR_LOANVIEW,
  SAVE_EVAL_LOANVIEW,
  SET_FHLMC_UPLOAD_RESULT,
  SET_RESOLUTION_AND_INVSTR_HRCHY,
  GET_ELIGIBLE_DATA,
  SET_REQUEST_TYPE_DATA,
  SET_EVALID,
  SET_VALID_EVALDATA,
  SET_TRIAL_DISABLE_STAGER_BUTTON,
  CHECK_TRIAL_DISABLE_STAGER_BUTTON,
  SAVE_APPROVAL_DROPDOWN,
  SAVE_PREAPPROVAL_DROPDOWN,
  SUBMIT_TO_BOARDING_TEMPLATE,
  GET_CANCELLATION_REASON,
  SET_CANCELLATION_REASON,
  SET_SELECTED_CANCELLATION_REASON,
  CLEAR_CANCELLATION_REASONS,
  SET_DISABLE_GENERATE_BOARDING_TEMPLATE,
  STORE_INVEST_CD_AND_BRAND_NM,
  SET_EXCEPTION_REVIEW_INDICATOR,
  SET_EXCEPTION_REVIEW_COMMENTS,
  SET_CASEIDS,
  FETCH_CASEIDS,
  SET_ENQUIRY_CASE_ID,
  SET_DISABLE_SUBMITTOFHLMC,
  SET_TRIAL_DATE_INFO,
  UPDATE_TRIAL_PERIOD,
  UPDATE_TRIAL_PERIOD_RESULT,
  DISABLE_SAVE,
  DISABLE_FINANCE_CALC_TAB_BUTTON,
  RESET_DATA,
  SET_LOAN_NUMBER,
};
