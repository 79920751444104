
const incTypeMap = {
  RI: 'Rental Income',
  WE: 'Wage Earnings',
  MI: 'Miscellaneous',
  UE: 'Unemployment',
  FI: 'Fixed Income',
  SE: 'Self Employment',
  NI: 'No Income',
};


module.exports = {
  incTypeMap,
};
