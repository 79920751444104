import {
  FETCH_TOMBSTONE_DATA,
  GET_RFDTABLE_DATA,
  GET_RFD_DROPDOWN_DATA,
  SAVE_RFD_REQUEST,
  CLEAR_TOMBSTONE_DATA,
  SET_CHECKLIST_CENTERPANE,
  TOGGLE_VIEW,
  POPULATE_COLLATERAL_DROPDOWN,
  FETCH_COLLATERAL_DATA,
  ADD_LIEN_LOAN_BALANCE,
  SAVE_COLLATERAL_DATA,
  REFRESH_LIEN_BALANCES,
} from './types';

const fetchTombstoneData = (loanNumber, taskName, taskId) => ({
  type: FETCH_TOMBSTONE_DATA,
  payload: {
    loanNumber,
    taskName,
    taskId,
  },
});
const getRFDTableData = loanNumber => ({
  type: GET_RFDTABLE_DATA,
  payload: loanNumber,
});

const getRFDReasonDescDropdown = () => ({
  type: GET_RFD_DROPDOWN_DATA,
});

const onSubmitToRFDRequest = payload => ({
  type: SAVE_RFD_REQUEST,
  payload,
});

const clearTombstoneData = () => ({
  type: CLEAR_TOMBSTONE_DATA,
});

const checklistCenterPaneData = payload => ({
  type: SET_CHECKLIST_CENTERPANE,
  payload,
});

const toggleViewTypeAction = () => ({
  type: TOGGLE_VIEW,
});

const populateCollateralDropdownAction = payload => ({
  type: POPULATE_COLLATERAL_DROPDOWN,
  payload,
});

const fetchCollateralDataAction = payload => ({
  type: FETCH_COLLATERAL_DATA,
  payload,
});

const addLoanBalanceAction = payload => ({
  type: ADD_LIEN_LOAN_BALANCE,
  payload,
});

const saveCollateralDataAction = payload => ({
  type: SAVE_COLLATERAL_DATA,
  payload,
});

const refreshLienBalanceAction = payload => ({
  type: REFRESH_LIEN_BALANCES,
  payload,
});

export {
  fetchTombstoneData,
  getRFDTableData,
  getRFDReasonDescDropdown,
  onSubmitToRFDRequest,
  populateCollateralDropdownAction,
  clearTombstoneData,
  checklistCenterPaneData,
  toggleViewTypeAction,
  fetchCollateralDataAction,
  addLoanBalanceAction,
  saveCollateralDataAction,
  refreshLienBalanceAction,
};
