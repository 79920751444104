const WIDGET_TOGGLE = 'app/widgets/WIDGET_TOGGLE';
const TOGGLE_WIDGET_SAGA = 'app/widgets/TOGGLE_WIDGET_SAGA';
const SET_DISABLED_WIDGETS = 'app/widgets/SET_DISABLED_WIDGETS';
const RESET_WIDGET_DATA = 'app/widgets/RESET_WIDGET_DATA';

export {
  TOGGLE_WIDGET_SAGA,
  WIDGET_TOGGLE,
  SET_DISABLED_WIDGETS,
  RESET_WIDGET_DATA,
};
