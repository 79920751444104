const RADIO_BUTTONS = 'radio';
const TEXT = 'text';
const MULTILINE_TEXT = 'multiline-text';
const NUMBER = 'number';
const DATE = 'date';
const DROPDOWN = 'dropdown';
const LABEL_WITH_ICON = 'label-with-icon';
const CREATE_SELECT = 'create-select';
const CALCULATION = 'calculation';
const BUTTON = 'button';
const OPTIONS = 'options';
const TASK_SECTION = 'task-section';
const CURRENCY = 'currency';
const ADDRESS = 'address';
const AMOUNTCALCULATION = 'amount-calculation';
const SHARED_INCOME = 'shared-income';
const DISPLAYVALUE = 'display-value';
const DELETE = 'delete';
const SLA_RULES = 'sla-rules';
const CHECKBOX = 'checkbox';
const LINK = 'link';
const TABS = 'tabs';
const DIVIDER = 'divider';
const INCOME_CALCULATOR = 'income-calculator';
const EXPENSE_CALCULATOR = 'expense-calculator';
const READ_ONLY_TEXT = 'read-only-text';

const componentTypes = {
  INCOME_CALCULATOR,
  TABS,
  LINK,
  DIVIDER,
  RADIO_BUTTONS,
  TEXT,
  MULTILINE_TEXT,
  NUMBER,
  DROPDOWN,
  LABEL_WITH_ICON,
  DATE,
  CREATE_SELECT,
  CALCULATION,
  BUTTON,
  OPTIONS,
  TASK_SECTION,
  CURRENCY,
  ADDRESS,
  DELETE,
  AMOUNTCALCULATION,
  DISPLAYVALUE,
  SHARED_INCOME,
  SLA_RULES,
  CHECKBOX,
  READ_ONLY_TEXT,
  EXPENSE_CALCULATOR,
};

export default componentTypes;
